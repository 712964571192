import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/joy";
import {
  IFondo,
  IMateriale,
  IQualita,
  IStatoLavorazione,
} from "../../../models";
import { IFilterOption } from "../../../models/utlis";
import SelectMaterialeFilters from "./SelectMaterialeFilters";
import SelectMaterialeGrid from "./SelectMaterialeGrid";

interface ISelectMaterialeProps {
  filteredMateriali: IQualita[];
  materiali: IQualita[];
  tipologie: IMateriale[];
  colori: IFondo[];
  lavorazioni: IStatoLavorazione[];
  spessoriDisponibili: number[];
  filters: IFilterOption[];
  onView: (materiale: IQualita) => void;
  onSetFilters: (filters: IFilterOption[]) => void;
}

export default function SelectMateriale({
  filteredMateriali,
  materiali,
  tipologie,
  colori,
  filters,
  lavorazioni,
  spessoriDisponibili,
  onView,
  onSetFilters,
}: ISelectMaterialeProps) {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        "@media screen and (min-width: 992px)": {
          flexDirection: "row",
        },
      }}
    >
      <Box
        sx={{
          display: "none",
          "@media screen and (min-width: 992px)": {
            position: "relative",
            display: "flex",
            height: "100%",
            width: "25%",
          },
        }}
      >
        <SelectMaterialeFilters
          materiali={materiali}
          tipologie={tipologie}
          colori={colori}
          lavorazioni={lavorazioni}
          spessoriDisponibili={spessoriDisponibili}
          filters={filters}
          onSetFilters={onSetFilters}
        />
      </Box>
      <Box
        sx={{
          "@media screen and (min-width: 992px)": {
            display: "none",
          },
        }}
      >
        <AccordionGroup>
          <Accordion>
            <AccordionSummary>
              <Typography sx={{ color: "rgb(86, 185, 58)" }}>Filtri</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SelectMaterialeFilters
                materiali={materiali}
                tipologie={tipologie}
                colori={colori}
                lavorazioni={lavorazioni}
                spessoriDisponibili={spessoriDisponibili}
                filters={filters}
                onSetFilters={onSetFilters}
              />
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
      </Box>
      <Box sx={{ flex: 1, position: "relative" }}>
        <SelectMaterialeGrid materiali={filteredMateriali} onView={onView} />
      </Box>
    </Box>
  );
}
