import { Box } from "@mui/joy";
import { useMemo } from "react";
import { createFilter } from "../../lib/filters";
import { isNotEmpty } from "../../lib/utils";
import {
  IDettaglio,
  IFondo,
  IMateriale,
  IQualita,
  IStatoLavorazione,
} from "../../models";
import { EOptionType, IFilterOption } from "../../models/utlis";
import SelectLotto from "./components/SelectLotto";
import SelectMateriale from "./components/SelectMateriale";

export interface IListProps {
  filters: IFilterOption[];
  setFilters: (filters: IFilterOption[]) => void;
  materialeSelected: IQualita | null;
  setMaterialeSelected: (materiale: IQualita | null) => void;
  dettagli: IDettaglio[];
}

export default function List({
  filters,
  setFilters,
  materialeSelected,
  setMaterialeSelected,
  dettagli,
}: IListProps) {
  const filteredDettagli = useMemo(() => {
    if (!!filters && !!filters.length) {
      const filter = createFilter(filters);
      return dettagli.filter((item) => filter(item));
    } else {
      return dettagli;
    }
  }, [dettagli, filters]);

  const materiali = useMemo(() => {
    const _materiali: IQualita[] = dettagli
      .map((dettaglio) => dettaglio.lotto?.qualita)
      .filter(isNotEmpty)
      .reduce(
        (prev: IQualita[], curr) =>
          !prev.find((l) => l.id === curr.id) ? [...prev, curr] : prev,
        []
      );
    return _materiali.sort((a, b) =>
      a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1
    );
  }, [dettagli]);

  const filteredMateriali = useMemo(() => {
    const _materiali: IQualita[] = filteredDettagli
      .map((dettaglio) => dettaglio.lotto?.qualita)
      .filter(isNotEmpty)
      .reduce(
        (prev: IQualita[], curr) =>
          !prev.find((l) => l.id === curr.id) ? [...prev, curr] : prev,
        []
      );
    return _materiali.sort((a, b) =>
      a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1
    );
  }, [filteredDettagli]);

  const tipologie = useMemo(() => {
    const _tipologie: IMateriale[] = dettagli
      .map((dettaglio) => dettaglio.lotto?.materiale)
      .filter(isNotEmpty)
      .reduce(
        (prev: IMateriale[], curr) =>
          !prev.find((l) => l.id === curr.id) ? [...prev, curr] : prev,
        []
      );
    return _tipologie.sort((a, b) =>
      a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1
    );
  }, [dettagli]);

  const colori = useMemo(() => {
    const _colori = dettagli
      .map((dettaglio) => dettaglio.lotto?.fondo)
      .filter(isNotEmpty)
      .reduce(
        (prev: IFondo[], curr) =>
          !prev.find((l) => l.id === curr.id) ? [...prev, curr] : prev,
        []
      );
    return _colori.sort((a, b) =>
      a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1
    );
  }, [dettagli]);

  const lavorazioni = useMemo(() => {
    const _lavorazioni = dettagli
      .map((dettaglio) => dettaglio.stato_lavorazione)
      .filter(isNotEmpty)
      .reduce(
        (prev: IStatoLavorazione[], curr) =>
          !prev.find((l) => l.id === curr.id) ? [...prev, curr] : prev,
        []
      );
    return _lavorazioni.sort((a, b) =>
      a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1
    );
  }, [dettagli]);

  const spessoriDisponibili = useMemo(() => {
    const _spessori = dettagli
      .map((dettaglio) => dettaglio.misZ)
      .filter(isNotEmpty)
      .reduce(
        (prev: number[], curr) =>
          !prev.find((misZ) => misZ === curr) ? [...prev, curr] : prev,
        []
      );
    return _spessori.sort((a, b) => (a > b ? 1 : -1));
  }, [dettagli]);

  const filteredLavorazioni = useMemo(() => {
    const _lavorazioni = dettagli
      .filter(
        (dettaglio) =>
          !!materialeSelected &&
          dettaglio.lotto?.qualita?.id === materialeSelected.id
      )
      .map((dettaglio) => dettaglio.stato_lavorazione)
      .filter(isNotEmpty)
      .reduce(
        (prev: IStatoLavorazione[], curr) =>
          !prev.find((l) => l.id === curr.id) ? [...prev, curr] : prev,
        []
      );
    return _lavorazioni.sort((a, b) =>
      a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1
    );
  }, [dettagli, materialeSelected]);

  const filteredSpessoriDisponibili = useMemo(() => {
    const _spessori = dettagli
      .filter(
        (dettaglio) =>
          !!materialeSelected &&
          dettaglio.lotto?.qualita?.id === materialeSelected.id
      )
      .map((dettaglio) => dettaglio.misZ)
      .filter(isNotEmpty)
      .reduce(
        (prev: number[], curr) =>
          !prev.find((misZ) => misZ === curr) ? [...prev, curr] : prev,
        []
      );
    return _spessori.sort((a, b) => (a > b ? 1 : -1));
  }, [dettagli, materialeSelected]);

  const onBack = () => {
    const _filters = [...filters].filter(
      (opt) => !(opt.type === EOptionType.qualita)
    );
    setFilters(_filters);
    setMaterialeSelected(null);
  };
  const onView = (materiale: IQualita) => {
    const _filters = [...filters].filter(
      (opt) => !(opt.type === EOptionType.qualita)
    );
    if (!!materiale.id)
      setFilters([
        ..._filters,
        {
          type: EOptionType.qualita,
          numericValue: materiale.id,
        },
      ]);
    else setFilters(_filters);
    setMaterialeSelected(materiale);
  };

  return (
    <Box sx={{ height: "100%" }}>
      {!materialeSelected && (
        <SelectMateriale
          filteredMateriali={filteredMateriali}
          materiali={materiali}
          tipologie={tipologie}
          colori={colori}
          lavorazioni={lavorazioni}
          spessoriDisponibili={spessoriDisponibili}
          filters={filters}
          onSetFilters={setFilters}
          onView={onView}
        />
      )}
      {!!materialeSelected && (
        <SelectLotto
          dettagli={filteredDettagli}
          materialeSelected={materialeSelected}
          lavorazioni={filteredLavorazioni}
          spessoriDisponibili={filteredSpessoriDisponibili}
          filters={filters}
          onSetFilters={setFilters}
          onBack={onBack}
        />
      )}
    </Box>
  );
}
