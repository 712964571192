import { Box, Typography } from "@mui/joy";
import { GridChildComponentProps } from "react-window";
import mockBackground from "../../../assets/background.jpg";
import { BASE_URL } from "../../../config";
import { ILotto } from "../../../models";

const IMAGE_URL = `${BASE_URL}image/`;

export interface IGridCellProps extends GridChildComponentProps<ILotto[][]> {
  onView: (id: number) => void;
}

export const LottoGridCell = ({
  columnIndex,
  rowIndex,
  style,
  data,
  onView,
}: IGridCellProps) => {
  const item = data[rowIndex][columnIndex];
  if (!item) return null;
  const cover = item.images?.find((image) => image.isCover) ?? item.images?.[0];
  const background = cover ? `${IMAGE_URL}${cover.mdPath}` : mockBackground;

  return (
    <Box
      style={style}
      sx={{
        boxSizing: "border-box",
        position: "relative",
        padding: 2,
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            height: "80%",
            backgroundImage: `url('${background}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              top: 0,
              left: 0,
              position: "absolute",
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(86, 185, 58, 0.7)",
              opacity: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",

              transition: "opacity .15s ease-in-out",

              "&:hover": {
                opacity: 0.3,
              },
            }}
            onClick={() => onView(item.id)}
          ></Box>
        </Box>
        <Box
          sx={{
            height: "20%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between" }}
              onClick={() => onView(item.id)}
            >
              <Typography level="title-lg" sx={{ color: "rgb(86, 185, 58)" }}>
                {item.codice}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                overflow: "auto",
                pr: 1,
                "::-webkit-scrollbar": {
                  width: "6px",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "rgba(0, 0, 0, .20)",
                },
                "::-webkit-scrollbar-thumb": {
                  background: "#56b93a",
                  borderRadius: "5px",
                },
              }}
            >
              {item.dettagli?.map((dett) => (
                <Box
                  key={dett.id}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Box sx={{ display: "flex", width: 60 }}>
                    <Typography level="body-xs">N.</Typography>
                    <Typography level="body-xs" sx={{ pl: 1, color: "#fff" }}>
                      {dett.pezzi}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", width: 120 }}>
                    <Typography level="body-xs">Cm</Typography>
                    <Typography level="body-xs" sx={{ pl: 1, color: "#fff" }}>
                      {`${dett.misX} x ${dett.misY} x ${dett.misZ}`}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", width: 80 }}>
                    <Typography level="body-xs">Fin.</Typography>
                    <Typography level="body-xs" sx={{ pl: 1, color: "#fff" }}>
                      {dett.stato_lavorazione?.nome ?? "---"}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
