import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Option,
  Select
} from "@mui/joy";
import { useEffect, useMemo, useState } from "react";
import {
  IFondo,
  IMateriale,
  IQualita,
  IStatoLavorazione,
} from "../../../models";
import { EOptionType, IFilterOption } from "../../../models/utlis";

interface ISelectMaterialeFiltersProps {
  materiali: IQualita[];
  tipologie: IMateriale[];
  colori: IFondo[];
  lavorazioni: IStatoLavorazione[];
  spessoriDisponibili: number[];
  filters: IFilterOption[];
  onSetFilters: (filters: IFilterOption[]) => void;
}

export default function SelectMaterialeFilters({
  materiali,
  tipologie,
  colori,
  lavorazioni,
  spessoriDisponibili,
  filters,
  onSetFilters,
}: ISelectMaterialeFiltersProps) {
  //const [searchText, setSearchText] = useState<string>("");
  /* const [selectedMaterialeAutocomplete, setSelectedMaterialeAutocomplete] =
    useState<{
      id: number;
      label: string;
    } | null>(null); */
  const [selectedMateriale, setSelectedMateriale] = useState<string | null>(
    null
  );
  const [selectedTipologia, setSelectedTipologia] = useState<string | null>(
    null
  );
  const [selectedColore, setSelectedColore] = useState<string | null>(null);
  const [selectedLavorazione, setSelectedLavorazione] = useState<string | null>(
    null
  );
  const [selectedSpessore, setSelectedSpessore] = useState<string | null>(null);
  /*  const onChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    const _filters = [...filters].filter(
      (opt) => !(opt.type === EOptionType.fullText)
    );
    if (!!text)
      onSetFilters([
        ..._filters,
        {
          type: EOptionType.fullText,
          stringValue: text,
        },
      ]);
    else onSetFilters(_filters);
  }; */

  const materialiList = useMemo(
    () =>
      materiali.map((materiale) => ({
        id: materiale.id,
        label: materiale.nome,
      })),
    [materiali]
  );
  /*   const onChangeMaterialeAutocomplete = (_event: React.SyntheticEvent, value: unknown) => {
    const _filters = [...filters].filter(
      (opt) => !(opt.type === EOptionType.qualita)
    );
    if (value) {
      const v = value as { id: number; label: string };
      onSetFilters([
        ..._filters,
        {
          type: EOptionType.qualita,
          numericValue: v.id,
        },
      ]);
    } else {
      onSetFilters(_filters);
    }
  }; */

  const onChangeMateriale = (
    _event:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element, Element>
      | null,
    value: string | null
  ) => {
    const _filters = [...filters].filter(
      (opt) => !(opt.type === EOptionType.qualita)
    );
    if (value) {
      onSetFilters([
        ..._filters,
        {
          type: EOptionType.qualita,
          numericValue: parseInt(value),
        },
      ]);
    } else {
      onSetFilters(_filters);
    }
  };

  const onChangeTipologia = (
    _event:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element, Element>
      | null,
    value: string | null
  ) => {
    const _filters = [...filters].filter(
      (opt) => !(opt.type === EOptionType.materiale)
    );
    if (value) {
      onSetFilters([
        ..._filters,
        {
          type: EOptionType.materiale,
          numericValue: parseInt(value),
        },
      ]);
    } else {
      onSetFilters(_filters);
    }
  };

  const onChangeColore = (
    _event:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element, Element>
      | null,
    value: string | null
  ) => {
    const _filters = [...filters].filter(
      (opt) => !(opt.type === EOptionType.fondo)
    );
    if (value) {
      onSetFilters([
        ..._filters,
        {
          type: EOptionType.fondo,
          numericValue: parseInt(value),
        },
      ]);
    } else {
      onSetFilters(_filters);
    }
  };
  const onChangeLavorazione = (
    _event:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element, Element>
      | null,
    value: string | null
  ) => {
    const _filters = [...filters].filter(
      (opt) => !(opt.type === EOptionType.statoLavorazione)
    );
    if (value) {
      onSetFilters([
        ..._filters,
        {
          type: EOptionType.statoLavorazione,
          numericValue: parseInt(value),
        },
      ]);
    } else {
      onSetFilters(_filters);
    }
  };
  const onSelectedSpessore = (
    _event:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element, Element>
      | null,
    value: string | null
  ) => {
    const _filters = [...filters].filter(
      (opt) => !(opt.type === EOptionType.misZ)
    );
    if (value) {
      onSetFilters([
        ..._filters,
        {
          type: EOptionType.misZ,
          numericValues: [parseFloat(value), parseFloat(value)],
        },
      ]);
    } else {
      onSetFilters(_filters);
    }
  };
  const onResetFilters = () => {
    const _filters = [...filters].filter(
      (opt) =>
        ![
          EOptionType.fullText,
          EOptionType.materiale,
          EOptionType.fondo,
          EOptionType.statoLavorazione,
          EOptionType.misZ,
        ].includes(opt.type)
    );
    onSetFilters(_filters);
  };

  useEffect(() => {
    const tipologia =
      filters.find((filter) => filter.type === EOptionType.materiale)
        ?.numericValue ?? null;
    const materiale =
      filters.find((filter) => filter.type === EOptionType.qualita)
        ?.numericValue ?? null;
    const colore =
      filters.find((filter) => filter.type === EOptionType.fondo)
        ?.numericValue ?? null;
    /* const text =
      filters.find((filter) => filter.type === EOptionType.fullText)
        ?.stringValue ?? ""; */
    const finitura =
      filters.find((filter) => filter.type === EOptionType.statoLavorazione)
        ?.numericValue ?? "";
    const misZArray =
      filters.find((filter) => filter.type === EOptionType.misZ)
        ?.numericValues ?? "";
    setSelectedMateriale(materiale?.toString() ?? null);
    setSelectedTipologia(tipologia?.toString() ?? null);
    setSelectedColore(colore?.toString() ?? null);
    //setSearchText(text);
    setSelectedLavorazione(finitura?.toString() ?? null);
    setSelectedSpessore(misZArray?.[0]?.toString() ?? null);
  }, [filters, materialiList]);
  return (
    <Box
      sx={{ height: "100%", width: "100%", backgroundColor: "rgba(0,0,0,.20)" }}
    >
      <Box
        sx={{
          px: 1,
          "@media screen and (min-width: 992px)": {
            px: 4,
            py: 16,
          },
        }}
      >
        <FormControl sx={{ py: 1 }}>
          <FormLabel color="primary">TIPOLOGIA</FormLabel>
          <Select
            placeholder="Tipologia…"
            variant="plain"
            value={selectedTipologia}
            onChange={onChangeTipologia}
          >
            <Option value="">---</Option>
            {tipologie.map((tipologia) => (
              <Option key={tipologia.id} value={tipologia.id.toString()}>
                {tipologia.nome}
              </Option>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ py: 1 }}>
          <FormLabel color="primary">MATERIALE</FormLabel>
          <Select
            placeholder="Materiale…"
            variant="plain"
            value={selectedMateriale}
            onChange={onChangeMateriale}
          >
            <Option value="">---</Option>
            {materiali.map((materiale) => (
              <Option key={materiale.id} value={materiale.id.toString()}>
                {materiale.nome}
              </Option>
            ))}
          </Select>
        </FormControl>
        {/* 
        <FormControl sx={{ py: 1 }}>
          <FormLabel color="primary">MATERIALE</FormLabel>
          <Input
            variant="plain"
            placeholder="Materiale…"
            value={searchText}
            onChange={onChangeSearchText}
          />
        </FormControl>
        
        <FormControl sx={{ py: 1 }}>
          <FormLabel color="primary">MATERIALE</FormLabel>
          <Autocomplete
            placeholder="Materiale…"
            variant="plain"
            value={selectedMateriale}
            onChange={onChangeMateriale}
            options={materialiList}
          />
        </FormControl>
        */}
        <FormControl sx={{ py: 1 }}>
          <FormLabel color="primary">COLORE</FormLabel>
          <Select
            placeholder="Colore…"
            variant="plain"
            value={selectedColore}
            onChange={onChangeColore}
          >
            <Option value="">---</Option>
            {colori.map((colore) => (
              <Option key={colore.id} value={colore.id.toString()}>
                {colore.nome}
              </Option>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ py: 1 }}>
          <FormLabel color="primary">SPESSORE</FormLabel>
          <Select
            placeholder="Spessore..."
            variant="plain"
            value={selectedSpessore}
            onChange={onSelectedSpessore}
          >
            <Option value="">---</Option>
            {spessoriDisponibili.map((misZ) => (
              <Option key={misZ} value={misZ.toString()}>
                {misZ}
              </Option>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ py: 1 }}>
          <FormLabel color="primary">FINITURA</FormLabel>
          <Select
            placeholder="Finitura…"
            variant="plain"
            value={selectedLavorazione}
            onChange={onChangeLavorazione}
          >
            <Option value="">---</Option>
            {lavorazioni.map((lavorazione) => (
              <Option key={lavorazione.id} value={lavorazione.id.toString()}>
                {lavorazione.nome}
              </Option>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ py: 2, display: "flex", justifyContent: "space-between" }}>
          <Button onClick={onResetFilters}>RESET FILTRI</Button>
        </Box>
      </Box>
    </Box>
  );
}
